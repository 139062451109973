export default function Modal({ setOpenModal,title,children }) {
    return (
        <>
            <div className="fixed inset-0 z-10 overflow-y-auto transition-all">
                <div
                    className="fixed inset-0 w-full h-full bg-black opacity-40"
                    onClick={() => setOpenModal(false)}
                ></div>
                <div className="flex items-center min-h-screen px-4 py-8">
                    <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                        <div className="mt-3 sm:flex">
                            <div className="mt-2 text-left sm:ml-4 sm:text-left">
                                <div><button className="text-xl p-2 rounded-md outline-none border ring-offset-2 ring-indigo-600 focus:ring-2"
                                onClick={() => setOpenModal(false)}
                                >&#x2717;</button></div>
                                <h4 className="">
                                    {title}
                                </h4>
                                <div className="mt-2 text-[15px] leading-relaxed text-gray-500">
                                    {children}
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}    