import axios from "axios";
import { motion } from "framer-motion";
import { useState } from "react";
import authService from "../services/auth-service";
import { useNavigate } from "react-router-dom";
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import * as scrollList from '../components/list.json'
export default function Signin(){
    const [username,setUserName]=useState('');
    const [password,setPassword]=useState('');
    const [errMsg,setErrMsg]=useState({
        err:false,
        msg:''
    })
    const navigate=useNavigate();
    const setPass=(text)=>{
        setPassword(text)
    }
    const setUser=(text)=>{
        setUserName(text)
    }
    const list = {
        visible: {
          opacity: 1,
          transition: {
            when: "beforeChildren",
            staggerChildren: 0.3,
          },
        },
        hidden: {
          opacity: 0,
          transition: {
            when: "afterChildren",
          },
        },
      }
      
      const item = {
        visible: { opacity: 1, x: 0 },
        hidden: { opacity: 0, x: -100 },
      }
    return(
        <motion.div 
        className="flex flex-col justify-center items-center"
        initial="hidden"
        animate="visible"
        variants={list}
        >
            <p className="text-2xl p-2 my-4">PCB Stocks Inventory</p>
            <p className="text-sm p-2 mb-2 text-gray-400">Core Technologies</p>
            <Player
            autoplay
            loop
            src={scrollList}
            />
            <label className="mt-2">Enter Username</label>
            <motion.input variants={item} className="w-1/2 m-2 border-red-100 border-2 p-2" type={'text'} placeholder='Name' onChange={(e)=>setUser(e.target.value)} />
            <label className="mt-2">Password</label>
            <motion.input variants={item} className="w-1/2 m-2 border-red-100 border-2 p-2"  type={'password'} placeholder="Password" onChange={(e)=>setPass(e.target.value)} />
            <motion.button variants={item} className="bg-gray-300 p-2 rounded m-2" onClick={()=>LogInUser()}>Signin </motion.button>
            {errMsg.err?
            (
                <motion.div animate={{x:0}} initial={{x:-200}} className="text-red-500">
                    {errMsg.msg}
                </motion.div>
            ):
            (
                <div>
                </div>
            )
        
            }
        </motion.div>
    )

    function LogInUser(){
        setErrMsg({err:false,msg:''})
        let options={
            method:'POST',
            headers:{
                'content-type':'application/json'
            },
            body:JSON.stringify({
                username:username,
                password:password
            })
        }
        console.log(username+" "+password)
        /*
        let fetchRes=fetch('http://127.0.0.1:3001/users/signin',options)
        fetchRes.then(res=>{
            res.json().then(d=>console.log(d))
        })*/
        let tok=authService.login(username,password)
        tok.then((toke)=>{
           if(toke===401){
            console.log('Incorrect Username/Password');
            setErrMsg({err:true,msg:'Incorrect Username/Password'})

           }
            else if(authService.getCurrentUser()){
                navigate('/dash')
            }
        })
    }
}