import { Navigate } from "react-router-dom"
import authService from "./auth-service"


const Protected=({children})=>{
    if(!authService.getCurrentUser()){
        return <Navigate to='/login' replace />
    }
    return children;
}
export default Protected;