
import React, { useState } from "react";
import { motion } from "framer-motion";
import Modal from "../components/Modal";
import PCBData from "../services/apisCall"
import authService from "../services/auth-service";
import { useNavigate } from "react-router-dom";
export default function Dash(){
    const navigate=useNavigate();
    const [openAllPCB,setAllPCB]=useState(false)
    const [searchMod,setSearchMod]=useState(false)
    const [searchString,setSearchString]=useState('')
    const [issueSearch,setIssueSearch]=useState('');
    const [searchList,setSerchList]=useState([])
    const [allPcbList,setAllPcbList]=useState([])
    const [issueItem,setIssueItem]=useState([]);
    const [issueCount,setIssueCount]=useState(0);
    const [issueModal,setIssueModal]=useState(false)
    const OpenPCBModal=()=>{
        let list=PCBData.getAllPCB()
        list.then((d)=>{
            //console.log(d)
            setAllPcbList(d)
        })
        setAllPCB(!openAllPCB)
    }
    const SearchPCB=()=>{
       let list=PCBData.searchPcb(searchString)
       list.then((d)=>{
        console.log({incoming:d})
        setSerchList(d)
       })
    }
    const issuePreSearch=()=>{
        let list=PCBData.preIssueSearch(issueSearch)
        list.then((d)=>{
            setIssueItem([d])
        })
    }
    const checkAndIssue=()=>{
        let remain=(issueItem[0].in_count-issueItem[0].out_count)
        console.log(remain)
        if(issueCount<=remain){
            let res=PCBData.issuePCB(issueItem[0],issueCount)
            res.then((d)=>{
                console.log(d)
                if(d.rowsUpdate>0){
                    alert('Updated')
                    issuePreSearch()
                }
            })
        }else{
            alert('Issue Less')
        }
    }
    function logoutFr(){
        authService.logout()
        navigate('/login');
    }
    return(
        <motion.div className="flex flex-col">
            <motion.div className="p-4 flex justify-between">
                <div className="text-2xl align-middle">Stocks</div>
            <button onClick={()=>logoutFr()} className="bg-red-700 p-2 rounded m-2 flex text-white"><span className="mx-2 material-symbols-outlined">
logout
</span> <span className="">Logout Exit</span></button>
            </motion.div>

            <div className="flex md:flex-row flex-col p-2">
                <motion.div animate={{backgroundColor: ['hsl(0, 100, 80)', 'hsl(-120, 100, 100)']}} transition={{repeat:Infinity,repeatType:'mirror',duration:5}} className="rounded p-2 border-red-300 border m-2 lg:w-48 lg:h-48 flex flex-col justify-center items-center cursor-pointer shadow-xl"><span className="text-2xl material-symbols-outlined">add_notes</span><span>Add New PCB</span></motion.div>
                <motion.div animate={{backgroundColor: ['hsl(80, 100, 80)', 'hsl(-120, 100, 100)']}} transition={{repeat:Infinity,repeatType:'mirror',duration:5}} onClick={()=>setSearchMod(true)} className="rounded p-2 border-red-300 border m-2 lg:w-48 lg:h-48 flex flex-col justify-center items-center cursor-pointer shadow-xl"><span className="text-2xl material-symbols-outlined">content_paste_search</span><span>Search PCB</span></motion.div>
                <motion.div animate={{backgroundColor: ['hsl(50, 100, 80)', 'hsl(-120, 100, 100)']}} transition={{repeat:Infinity,repeatType:'mirror',duration:5}} onClick={()=>OpenPCBModal()} className="rounded p-2 border-red-300 border m-2 lg:w-48 lg:h-48 flex flex-col justify-center items-center cursor-pointer shadow-xl"><span className="text-2xl material-symbols-outlined">fact_check</span><span>PCB List</span></motion.div>
                <motion.div animate={{backgroundColor: ['hsl(150, 100, 80)', 'hsl(-120, 100, 100)']}} transition={{repeat:Infinity,repeatType:'mirror',duration:5}} onClick={()=>setIssueModal(true)} className="rounded p-2 border-red-300 border m-2 lg:w-48 lg:h-48 flex flex-col justify-center items-center cursor-pointer shadow-xl"><span className="text-2xl material-symbols-outlined">playlist_add_check</span><span>PCB Issue/Out</span></motion.div>
            </div>
            <p>{}</p>
            {openAllPCB&&<Modal setOpenModal={setAllPCB} >
                <div className="table-wrp">
                    <table className="table-auto text-xs">
                        <thead className="bg-black border-b sticky top-2">
                        <tr>
                            <th className="p-2 rounded text-white">PCB No</th>
                            <th className="p-2 rounded text-white">Box No</th>
                            <th className="p-2 rounded text-white">PCB Name</th>
                            <th className="p-2 rounded text-white">Bal</th>
                            <th className="p-2 rounded text-white">In/Out</th>
                            <th className="p-2 rounded text-white">In/Out Date</th>
                            
                        </tr>
                        </thead>
                        <tbody className="h-96 overflow-y-auto">
                    {allPcbList.map((list)=>{
                        return(
                            <tr className="border-red-300 border" key={list.pcb_no}>
                                <td className="text-indigo-500">{list.pcb_no}</td>
                                <td>{list.box_no}</td>
                                <td>{list.pcb_name} </td>
                                <td>{list.in_count-list.out_count}</td>
                                <td>{list.in_count} / {list.out_count?list.out_count:0} <small className="text-red-700">{list.last_updated_by}</small></td>
                                <td>{list.in_date} / {list.out_date}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                    </table>
                </div>
            </Modal> }
            {searchMod&&<Modal title={'Search PCB'} setOpenModal={setSearchMod}>
                <div>
                <div className="flex flex-row">
                    <input type={'text'} onChange={(e)=>setSearchString(e.target.value)} className="p-2 w-full border rounded" placeholder="PCB Number / PCB Name"/>
                    <button onClick={()=>SearchPCB()} className="p-2 bg-red-600 rounded m-2 text-white">Search</button>
                </div>
                    <div className="table-wrp">
                    <table className="table-auto text-sm">
                        <thead className="bg-black border-b sticky top-2">
                        <tr>
                            <th className="p-2 rounded text-white">PCB No</th>
                            <th className="p-2 rounded text-white">Box No</th>
                            <th className="p-2 rounded text-white">PCB Name</th>
                            <th className="p-2 rounded text-white">Bal</th>
                            <th className="p-2 rounded text-white">In/Out</th>
                            <th className="p-2 rounded text-white">In/Out Date</th>
                            
                        </tr>
                        </thead>
                        <tbody className="overflow-y-auto">
                    {searchList.map((list)=>{
                        return(
                            <tr className="border-red-300 border" key={list.pcb_no}>
                                <td className="text-indigo-500">{list.pcb_no}</td>
                                <td>{list.box_no}</td>
                                <td>{list.pcb_name} </td>
                                <td>{list.in_count-list.out_count}</td>
                                <td>{list.in_count} / {list.out_count?list.out_count:0} <small className="text-red-700">{list.last_updated_by}</small></td>
                                <td>{list.in_date} / {list.out_date}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                    </table>
                </div>
                </div>
                </Modal>}
                {issueModal&&<Modal title={'Issue PCB'} setOpenModal={setIssueModal}>
                <div>
                <div className="flex flex-row">
                    <input type={'text'} onChange={(e)=>setIssueSearch(e.target.value)} className="p-2 w-full border rounded" placeholder="PCB Number / PCB Name"/>
                    <button onClick={()=>issuePreSearch()} className="p-2 bg-red-600 rounded m-2 text-white">Search</button>
                </div>
                    <div className="table-wrp  items-center justify-center ">
                    <table className=" p-2 mt-2 table-auto">
                        <thead className="bg-black border-b sticky top-2">
                        <tr>
                            <th className="p-2 text-white">PCB No</th>
                            <th className="p-2 text-white">Box No</th>
                            <th className="p-2 text-white">PCB Name</th>
                            <th className="p-2 text-white">Bal</th>
                            <th className="p-2 bg-green-400 text-center">Action</th>
                            
                        </tr>
                        </thead>
                        <tbody className="overflow-y-auto">
                    {issueItem.map((list)=>{
                        return(
                            <tr className="border-red-300 border" key={list.pcb_no}>
                                <td className="text-indigo-500">{list.pcb_no}</td>
                                <td>{list.box_no}</td>
                                <td>{list.pcb_name} </td>
                                <td><b>{list.in_count-list.out_count}</b></td>
                                <td><input onChange={(e)=>setIssueCount(e.target.value)} type={'number'} className="border p-2 m-2" /> <button onClick={()=>checkAndIssue()} className="bg-indigo-600 text-white rounded p-2 m-2">Issue</button></td>
                            </tr>
                        )
                    })}
                    </tbody>
                    </table>
                </div>
                </div>
                </Modal>}
           
        </motion.div>
    )
}