import axios from "axios";
import { useNavigate } from "react-router-dom";
const API_URL = "https://STOCKSAPI.CORE-TECHNOLOGIES.COM/users";

class AuthService {

  login(username, password) {
      return axios.post(API_URL+'/signin',{
        username:username,
        password,password
      }).catch((err)=>{
        //console.log(err.response.status)
        return err.response;
      })
      .then(response => {
        //console.log(response)
        //console.log(response.status)
        if(response.status!==401){
          if (response.data.token) {
            localStorage.setItem("user", JSON.stringify(response.data));
  
          }
        }else{
          return response.status
        }
       

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
    
  }

  register(username, email, password) {
    return axios.post(API_URL + "signup", {
      username,
      email,
      password
    });
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));;
  }
}

export default new AuthService();