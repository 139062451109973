import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router,Routes as Switch,Route,Link } from 'react-router-dom';
import Dash from './pages/dashboard';
import Signin from './pages/signin';
import { useEffect, useState } from 'react';
import authService from './services/auth-service';
import Protected from './services/ProtectedRoute';
function App() {

  return (
        <Switch>
          <Route exact path="/dash" element={
          <Protected>
            <Dash/>
          </Protected>
          }/>
          <Route exact path="/" element={
          <Protected>
            <Dash/>
          </Protected>
          }/>
          <Route exact path='/login' element={<Signin/>}/>
        </Switch>
  );
}

export default App;
