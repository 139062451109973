import axios from "axios";
import authService from "./auth-service";

const api_url="https://STOCKSAPI.CORE-TECHNOLOGIES.COM/";

class PCBData{
    getAllPCB(){
        const tok=authService.getCurrentUser()
        return axios.get(api_url+'pcb/total',{
            headers:{
                'x-access-token':tok.token
            }
        }).then((res)=>{
            console.log(res)
            return res.data})
    }
    searchPcb(pcb){
        const tok=authService.getCurrentUser()
        return axios.get(api_url+"pcb/pcbno",{
            headers:{
                'x-access-token':tok.token
            },
            params:{
                "pcbno":pcb
            }
        }).then((res)=>{
            console.log(res.data)
            return res.data
        })
    }
    preIssueSearch(pcb){
        const tok=authService.getCurrentUser()
        return axios.get(api_url+"pcb/prePcbNo",{
            headers:{
                'x-access-token':tok.token
            },
            params:{
                "pcbno":pcb
            }
        }).then((res)=>{
            console.log(res.data)
            return res.data
        })
    }
    issuePCB(pcbdata,count){
        const pcbno=pcbdata.pcb_no;
        console.log("PCB No:"+pcbno)
        const tok=authService.getCurrentUser()
        return axios.post(api_url+"pcb/updateOUT",{
            pcbNo:pcbno,
            outCount:count
        },{
            headers:{
                'x-access-token':tok.token
            }
        }).then((res)=>{
            return res.data
        })
    }
}

export default new PCBData();